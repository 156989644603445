import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { AppLayout } from '../../AppLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { appDefaultLanguage, AppLanguage } from '../../model/enums/AppLanguage';
import { appTranslations } from '../index';

const isValidLanguage = (pathname: string): pathname is AppLanguage => {
  return Object.values(AppLanguage).some((appLanguage) => appLanguage === pathname);
};

export const LocalizedRoute = (props: { children: React.ReactNode }) => {
  const [lang, setLang] = useState<AppLanguage>(appDefaultLanguage);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const clearedPath = pathname.replace('/', '');
    if (isValidLanguage(clearedPath)) {
      setLang(clearedPath);
    } else {
      navigate('/');
    }
  }, [pathname]);

  return (
    <IntlProvider locale={lang} messages={appTranslations[lang]}>
      <AppLayout>{props.children}</AppLayout>
    </IntlProvider>
  );
};
