import React, { createContext, useContext, useReducer } from 'react';
import { Reducer } from './Reducer';
import { Project } from '../model/Project';
import { Company } from '../model/Company';
import { ProjectSection } from '../model/ProjectSection';
import { Action } from './Action';

/**
 * State
 */

export interface AppState {
  company?: Company;
  project?: Project;
  activeSections: ProjectSection[];
}

const initialState: AppState = {
  activeSections: [],
};

/**
 * Context
 */

type AppContextType = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};

const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = (): AppContextType => useContext(AppContext);

/**
 * Provider
 */

export const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
