import { css } from 'styled-components';
import { Colors } from '../app/constants/colors';
import { Fonts } from '../app/constants/fonts';

export const cookiesStyles = css`
  #privacypolicies-com---nb {
    background-color: ${Colors.white} !important;
    color: ${Colors.text} !important;
    font-family: ${Fonts.base};
    font-weight: 400;
    font-style: normal;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .cc-nb {
      &-main-container {
        padding: 30px;
      }

      &-title-container {
        p {
          font-size: 1.2em;
        }
      }

      &-text-container {
        p {
          font-size: 0.9em;
        }
      }

      &-buttons-container {
        > * {
          border-radius: 6px;
        }

        .cc-nb-okagree {
          background-color: ${Colors.primary} !important;
        }
      }
    }
  }

  #privacypolicies-com---preferences-center {
    font-family: ${Fonts.base};

    .cc-cp-foot-save {
      border-radius: 6px;
      background-color: ${Colors.primary} !important;
    }
  }
`;
