import { TeamMember } from '../model/Project';
import React from 'react';
import styled from 'styled-components';
import { Device } from '../constants/device';

type Props = {
  member: TeamMember;
  itemsPerRow: number;
  importPictures?: boolean;
};

type TeamMemberItemProps = {
  itemsPerRow: number;
};

export const TeamMemberContainer = ({ member, itemsPerRow, importPictures = false }: Props) => (
  <TeamMemberItem itemsPerRow={itemsPerRow}>
    <TeamMemberImg>
      <img src={importPictures ? `https://raw.githubusercontent.com/mild-blue/about/master/people/photos/${member.photo}` : `/authors/${member.photo}`} alt={member.name} />
    </TeamMemberImg>
    <h3>{member.name}</h3>
    {member.positions.map((position, key) => (
      <p key={key}>{position}</p>
    ))}
  </TeamMemberItem>
);

const TeamMemberItem = styled.div<TeamMemberItemProps>`
  display: flex;
  flex: 1 0 calc(100% / ${(props) => props.itemsPerRow});
  max-width: calc(100% / ${(props) => props.itemsPerRow} - 60px);
  flex-direction: column;
  border-radius: 20px;
  text-align: center;
  align-items: center;

  p {
    margin: 0 0 10px;
    text-align: center;
    line-height: 1.4;
    opacity: 0.5;
  }

  ${Device.laptop} {
    flex: 1 0 100%;
    max-width: 100%;
  }
`;

const TeamMemberImg = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
