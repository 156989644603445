export const en: { [key: string]: string } = {
  /** Sections **/
  'section.about': 'About',
  'section.transition': 'Transition from SLP®',
  'section.demo': 'Demo',
  'section.contact': 'Contact',
  'section.features': 'Features',
  'section.references': 'References',
  'section.deployments': 'Our customers',
  'section.download': 'Download',
  'section.youtube': 'Video',
  'section.faq': 'FAQ',
  'section.teams': 'Team',
  'section.documents': 'Documents',

  /** Sections content */
  'section.demo.desc': 'Stop waiting.\nTry the web demo.',

  /** Common */
  error: 'Oops, something went wrong...',
  'error.button': 'Reload page',

  'mild-blue.coop': 'Created in collaboration with',
};

export type LanguageStrings = typeof en;
