import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import styled from 'styled-components';
import { Device } from '../../constants/device';
import { Colors } from '../../constants/colors';
import { Container } from '../Container';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { sanitize } from 'dompurify';

type StyledProjectPointProps = {
  icon: string;
};
const iconSrc = `${process.env.REACT_APP_API_URL}/images/icons`;

export const FAQ = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.faq?.questions.length) {
    return null;
  }

  return (
    <Section id="faq" inverse={props.inverse}>
      <Container>
        {project?.faq.disclaimer && <Disclaimer dangerouslySetInnerHTML={{ __html: project?.faq.disclaimer }} />}
        <FaqContainer>
          {project.faq.questions.map((faq, key) => {
            if (!faq.question || !faq.answer) {
              return null;
            }

            return (
              <FaqItem key={key} icon={iconSrc + '/'} className={props.inverse ? 'inverse' : ''}>
                <h3>{faq.question}</h3>
                <p dangerouslySetInnerHTML={{ __html: sanitize(faq.answer) }}></p>
              </FaqItem>
            );
          })}
        </FaqContainer>
      </Container>
    </Section>
  );
};

const FaqContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  ${Device.tablet} {
    flex-direction: column;
    margin: 0 -15px;
  }
`;

const FaqItem = styled.div<StyledProjectPointProps>`
  display: flex;
  width: calc(50% - 20px);
  flex-direction: column;
  padding: 25px;
  border-radius: 20px;
  text-align: center;
  background: ${Colors.white};

  &.inverse {
    background: ${Colors.background};
  }

  h3 {
    color: ${Colors.primaryDark};
  }

  ${Device.tablet} {
    width: 100%;
  }

  a {
    color: ${Colors.primary};
  }
`;

const Disclaimer = styled.p`
  a {
    color: ${Colors.primary};
  }
`;
