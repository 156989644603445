import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Device } from '../constants/device';
import { useAppContext } from '../state/StateProvider';
import { sanitize } from 'dompurify';

export const Banner = () => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.banner) {
    return null;
  }

  return (
    <BannerContainer>
      <p dangerouslySetInnerHTML={{ __html: sanitize(project.banner) }}></p>
    </BannerContainer>
  );
};
const BannerContainer = styled.div`
  background: ${Colors.darkerGrey};
  color: ${Colors.white};
  text-align: center;
  p {
    padding: 10px;
    margin: 0;
  }
  a {
    color: ${Colors.white};
  }
  ${Device.tablet} {
    font-size: 10px;
  }
`;
