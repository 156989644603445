import React from 'react';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../constants/colors';
import { rgba } from 'polished';
import { Sizes } from '../constants/sizes';
import { Device } from '../constants/device';
import main from '../../assets/images/main.png';

export const Jumbotron = (): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project) {
    return null;
  }

  return (
    <StyledJumbotron>
      <Container>
        <JumbotronLeft>
          <h1>{project.name}</h1>
          <DividingLine />
          <p>{project.subtitle}</p>
        </JumbotronLeft>
        <JumbotronRight>
          <JumbotronPictures>
            <JumbotronPicture src={main} />
          </JumbotronPictures>
          <BlueStripe />
        </JumbotronRight>
      </Container>
    </StyledJumbotron>
  );
};

const StyledJumbotron = styled.div`
  width: 100%;
  height: calc(100vh - ${Sizes.HeaderHeight}px);
  max-height: 800px;
  background: ${Colors.white};

  ${Container} {
    flex-direction: row;
    height: 100%;

    ${Device.desktop} {
      flex-direction: column;
    }
  }

  ${Device.laptop} {
    height: auto;
  }
`;

const JumbotronLeft = styled.div`
  flex: 1;
  padding-right: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${Device.desktop} {
    padding-right: 0;
    margin: 40px 0;
    align-items: center;
  }

  h1 {
    font-size: 58px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-top: 0;
    color: ${Colors.primaryDark};
    position: relative;

    ${Device.tablet} {
      font-size: 42px;
    }
  }

  p {
    margin: 0;
    font-size: 22px;
    max-width: 440px;

    ${Device.desktop} {
      max-width: none;
    }

    ${Device.tablet} {
      font-size: 18px;
      text-align: center !important;
    }
  }

  .buttons {
    display: flex;
    margin: 50px -15px;

    ${Device.mobile} {
      flex-direction: column;
    }

    button {
      margin: 0 15px;

      ${Device.mobile} {
        margin-top: 15px;
      }
    }
  }
`;

const JumbotronRight = styled.div`
  flex-basis: ${Sizes.JumbotronRightWidth}px;
  border-radius: 0 0 120px 120px;
  max-width: 45%;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  ${Device.desktop} {
    background: ${Colors.primary};
    flex-basis: 0;
    border-radius: 0;
    max-width: none;
    margin-left: -30px;
    margin-right: -30px;
  }
`;

const JumbotronPictures = styled.div`
  transform: translateX(-250px);
  display: flex;
  align-items: center;
  height: 100%;

  position: relative;
  z-index: 2;

  ${Device.fullHdDown} {
    padding-bottom: 80px;
    width: 20px;
  }

  ${Device.desktop} {
    width: 100%;
    justify-content: center;
    transform: none;
    padding-bottom: 0;
  }
`;

const slideUp = keyframes`
  from {
    opacity: 0;
    bottom: -30px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
`;

const JumbotronPicture = styled.img`
  max-height: 80%;
  filter: drop-shadow(0px 0px 12px ${rgba(0, 0, 0, 0.5)});
  transition:
    bottom 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  bottom: -30px;
  position: relative;

  ${Device.desktop} {
    height: auto;
    max-height: 440px;
    width: auto;
    padding: 30px;
  }

  ${Device.mobile} {
    width: 100%;
  }

  :only-child {
    transform: none !important;
    max-width: 100%;
  }

  :first-child {
    transform: rotate(-5deg);
    z-index: 1;
    animation: ${slideUp} 0.5s ease-in-out forwards;
  }

  :nth-child(2) {
    transform: rotate(5deg);
    z-index: 2;
    animation: ${slideUp} 0.5s ease-in-out forwards 250ms;
  }
`;

const BlueStripe = styled.div`
  background: ${Colors.primary};
  border-radius: 0 0 120px 120px;
  height: calc(100% + ${Sizes.HeaderHeight}px + 120px);
  width: 700px;

  display: flex;
  align-items: center;

  position: absolute;
  top: -${Sizes.HeaderHeight}px;
  right: 0;

  ${Device.fullHdDown} {
    width: 590px;
  }

  ${Device.desktop} {
    display: none;
  }
`;

const DividingLine = styled.div`
  background: ${Colors.primaryLight};
  height: 5px;
  width: 100px;
  margin-bottom: 20px;
`;
