import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Device } from '../constants/device';
import mildBlueLogo from '../../assets/images/mild-blue-small.svg';

type Props = {
  className?: string;
  logo?: string;
  width?: number;
};

export const ThemedLogo = ({ className, logo = mildBlueLogo, width = 74 }: Props): JSX.Element | null => (
  <LogoThemed className={className} logo={logo} width={width}>
    <img src={logo} alt="Mild Blue logo" />
  </LogoThemed>
);

const LogoThemed = styled.div<{ logo: string; width: number }>`
  width: ${(props) => `${props.width}px`};
  height: 60px;
  background: ${Colors.primary};
  mask: ${(props) => `url(${props.logo}) no-repeat center`};
  mask-size: contain;
  margin-right: 15px;

  ${Device.mobile} {
    width: 50px;
    height: 40px;
  }

  @supports not (mask-size: contain) {
    img {
      display: block;
    }
  }

  img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    display: none;
  }
`;
