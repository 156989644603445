import styled from 'styled-components';
import { Colors } from '../constants/colors';

export const TeamItemContainer = styled.div`
  a {
    color: ${Colors.primary};
  }
  align-items: center;
  display: flex;
  flex-direction: column;
`;
