import { AppState } from './StateProvider';
import { Project } from '../model/Project';
import { Company } from '../model/Company';
import { Action } from './Action';
import { ProjectSection } from '../model/ProjectSection';

export const Reducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'setCompany': {
      return {
        ...state,
        company: action.payload as Company,
      };
    }
    case 'setProject': {
      return {
        ...state,
        project: action.payload as Project,
      };
    }
    case 'setSections': {
      return {
        ...state,
        activeSections: action.payload as ProjectSection[],
      };
    }
    default: {
      return state;
    }
  }
};
