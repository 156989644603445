import { Company } from '../model/Company';
import { Project } from '../model/Project';
import { ProjectSection } from '../model/ProjectSection';

export type ActionType = 'setCompany' | 'setProject' | 'setSections';

export type Action = {
  type: ActionType;
  payload: Company | Project | ProjectSection[];
};

export const setGlobalCompany = (company: Company): Action => {
  return {
    type: 'setCompany',
    payload: company,
  };
};

export const setGlobalProject = (project: Project): Action => {
  return {
    type: 'setProject',
    payload: project,
  };
};

export const setSections = (sections: ProjectSection[]): Action => {
  return {
    type: 'setSections',
    payload: sections,
  };
};
