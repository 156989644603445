import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Container } from '../Container';
import { Colors } from '../../constants/colors';
import { InverseButton } from '../buttons/InverseButton';
import { useAppContext } from '../../state/StateProvider';
import { Device } from '../../constants/device';
import { rgba } from 'polished';

type DemoSectionProps = {
  bgImage: string;
};

export const Demo = (): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.demo) {
    return null;
  }

  const bgImage = `${process.env.REACT_APP_API_URL}/images/screenshots/${process.env.REACT_APP_PROJECT}/${project.demoScreenshot}`;

  return (
    <DemoSection id="demo" bgImage={bgImage}>
      <Container>
        <DemoSectionContent>
          <h1>
            <FormattedMessage id="section.demo.desc" />
          </h1>
          <InverseButton
            onClick={() => {
              if (project?.demo) {
                window.open(project.demo, '_blank')?.focus();
              }
            }}
          >
            Demo
          </InverseButton>
        </DemoSectionContent>
      </Container>
    </DemoSection>
  );
};

const DemoSection = styled.div<DemoSectionProps>`
  padding: 80px 0;
  color: ${Colors.white};
  min-height: 500px;
  display: flex;
  position: relative;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    height: calc(100% + 50px);
    width: calc(100% + 50px);
    top: -25px;
    left: -25px;
    position: absolute;
    z-index: 1;
    background: url(${(props) => props.bgImage}) ${rgba(Colors.primary, 1)} no-repeat 50%;
    background-size: cover;
    background-blend-mode: multiply;
    filter: blur(3px) brightness(0.7);
  }
`;

const DemoSectionContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Device.laptop} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  h1 {
    font-size: 62px;
    padding-right: 60px;
    white-space: pre;

    ${Device.tablet} {
      font-size: 42px;
      padding-right: 0;
      padding-bottom: 30px;
    }
    ${Device.mobile} {
      font-size: 26px;
      padding-right: 0;
      white-space: break-spaces;
    }
  }

  button {
    font-size: 24px;
    max-width: 280px;
    width: 100%;
    justify-content: center;
    line-height: 80px;
  }
`;
