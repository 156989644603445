import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import { Section } from '../Section';
import styled from 'styled-components';
import { ProjectSectionProps } from '../../model/ProjectSection';

export const Youtube = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.youtubeId) {
    return null;
  }

  return (
    <Section id="youtube" inverse={props.inverse}>
      <Container>
        <Wrapper>
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${project.youtubeId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={project.name}
          />
        </Wrapper>
      </Container>
    </Section>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
