import { AppLanguage } from '../model/enums/AppLanguage';

type CookieConsentScript = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  run: (params: any) => void;
};

export const useCookieConsent = () => {
  const run = (language: AppLanguage) => {
    const getCookieConsentScript = () => {
      if ('cookieconsent' in window && window.cookieconsent !== null && typeof window.cookieconsent === 'object' && 'run' in window.cookieconsent && typeof window.cookieconsent.run === 'function') {
        return window.cookieconsent as CookieConsentScript;
      }
    };

    const cookieConsentScript = getCookieConsentScript();
    if (!cookieConsentScript) {
      console.log('Cookie consent script has not been initialized.');
      return;
    }

    // Remove old cookies alert
    document.getElementById('privacypolicies-com---nb')?.remove();

    // Render new cookies alert
    cookieConsentScript.run({
      notice_banner_type: 'simple',
      consent_type: 'implied',
      palette: 'light',
      language: language,
      page_load_consent_levels: ['strictly-necessary', 'functionality', 'tracking', 'targeting'],
      notice_banner_reject_button_hide: false,
      preferences_center_close_button_hide: false,
      page_refresh_confirmation_buttons: false,
      website_name: 'slp.blue',
    });

    // When "Change my preferences" button is clicked, new modal is rendered.
    // We want to keep previously rendered modal because of attached listeners, so we remove the new one.
    // TODO: figure out a way to change language of the previously rendered modal
    document
      .getElementById('privacypolicies-com---nb')
      ?.querySelector('.cc-nb-changep')
      ?.addEventListener('click', () => {
        const preferencesModals = document.querySelectorAll('#privacypolicies-com---preferences-center');
        if (preferencesModals.length === 2) {
          const newModal = preferencesModals[preferencesModals.length - 1];
          newModal.remove();
        }
      });
  };

  return { run };
};
