import axios from 'axios';
import { getTeamMember } from './getTeamMember';
import { TeamMemberInterface } from '../model/ProjectInterface';

export const getOurTeam = async (namesToExclude: string[]): Promise<TeamMemberInterface[]> => {
  const response = await axios.get('https://raw.githubusercontent.com/mild-blue/about/master/people/sources.json');
  const memberNames: string[] = response.data;
  const filteredMemberNames = memberNames.filter((name) => !namesToExclude.includes(name));
  const membersPromises: Promise<TeamMemberInterface>[] = filteredMemberNames.map((name) => getTeamMember(name));
  const members: TeamMemberInterface[] = await Promise.all(membersPromises);
  return members;
};
