import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Device } from '../../constants/device';
import { Section } from '../Section';
import { ProjectSectionProps } from '../../model/ProjectSection';

export const Features = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.points.length) {
    return null;
  }

  return (
    <Section id="features" inverse={props.inverse}>
      <Container>
        <FeaturesSection>
          <StyledProjectPoints>
            {project.points.map((point, index) => {
              if (!point.title && !point.description) {
                return null;
              }

              return (
                <StyledProjectPoint key={index} className={index % 2 ? 'reverse' : ''}>
                  <StyledProjectScreenshot className={'image'}>
                    <img src={`/features/${point.image}`} alt={point.title} />
                  </StyledProjectScreenshot>
                  <StyledProjectText>
                    <h3>{point.title}</h3>
                    <p>{point.description}</p>
                  </StyledProjectText>
                </StyledProjectPoint>
              );
            })}
          </StyledProjectPoints>
        </FeaturesSection>
      </Container>
    </Section>
  );
};

const FeaturesSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  gap: 30px;

  ${Device.laptop} {
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
  }
`;

const StyledProjectScreenshot = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;

  ${Device.laptop} {
    padding-right: 0;
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 600px;
  }
`;

const StyledProjectText = styled.div`
  flex: 1;

  p {
    text-align: left;
  }

  h3 {
    color: ${Colors.primary};
    font-size: 28px;
  }

  ${Device.laptop} {
    p {
      text-align: center;
    }

    h3 {
      text-align: center;
      margin-top: 0;
      font-size: 24px;
    }
  }
`;

const StyledProjectPoints = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 60px;
  flex: 2;

  .reverse {
    flex-direction: row-reverse;

    .image {
      justify-content: end !important;
    }
  }

  ${Device.laptop} {
    .reverse {
      flex-direction: column-reverse;
    }

    .image {
      justify-content: center;
    }
  }
`;

const StyledProjectPoint = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  gap: 30px;

  ${Device.laptop} {
    flex-direction: column-reverse;
    flex-basis: 100%;
    width: 100%;
    margin: 0 0 20px;
  }
`;
