import React from 'react';
import { useAppContext } from '../state/StateProvider';
import styled from 'styled-components';
import { Container } from './Container';
import { Colors } from '../constants/colors';
import logo from '../../assets/images/mild-blue-white.svg';

export const Footer: React.FC = () => {
  const {
    state: { company },
  } = useAppContext();
  const year = new Date().getFullYear();

  if (!company) {
    return null;
  }

  return (
    <StyledFooter>
      <Container>
        <LogoHolder
          onClick={() => {
            if (company?.website) {
              window.open(company?.website, '_blank')?.focus();
            }
          }}
        >
          <img src={logo} alt={company.name + ' logo'} />
        </LogoHolder>
        <p>{company.slogan}</p>
        <div className="copyright">
          © {year} {company.name}
        </div>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background: ${Colors.primary};
  color: ${Colors.white};
  padding: 60px 0 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;

  img {
    max-width: 120px;
  }

  p {
    color: ${Colors.primaryLight};
  }

  .copyright {
    margin-top: 50px;
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
  }
`;

const LogoHolder = styled.div`
  cursor: pointer;
`;
