import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import styled from 'styled-components';
import { Container } from '../Container';
import { CooperationTeam } from '../CooperationTeam';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { OurTeam } from '../OurTeam';

export const Teams = (props: ProjectSectionProps) => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.teams.length) return null;

  return (
    <Section id="teams" inverse={props.inverse}>
      <Container>
        <TeamContainer>
          {project.teams.map((team, index) => {
            if (index === 0) return <OurTeam key={index} team={team} />;
            else return <CooperationTeam key={index} team={team} />;
          })}
        </TeamContainer>
      </Container>
    </Section>
  );
};

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  h3 {
    text-align: center;
  }
`;
