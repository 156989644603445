import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Team } from '../model/Project';
import { Device } from '../constants/device';
import { ExpandIcon } from './icons/ExpandIcon';
import { TeamMemberContainer } from './TeamMemberContainer';
import { MainMembersContainer } from './sections/MainMembersContainer';
import { TeamItemContainer } from './TeamItemContainer';

type TeamItemProps = {
  team: Team;
};

type ToggleButtonProps = {
  isOpen: boolean;
};

type CollapsibleContentProps = {
  isOpen: boolean;
  contentHeight: number;
};

export const OurTeam = (props: TeamItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef.current?.scrollHeight]);

  const mainMembers = props.team.teamMembers.slice(0, 4);
  const otherMembers = props.team.teamMembers.slice(4);

  return (
    <TeamItemContainer>
      <MainMembersContainer>
        {mainMembers.map((member, key) => (
          <TeamMemberContainer key={key} member={member} itemsPerRow={2} importPictures />
        ))}
      </MainMembersContainer>
      <OtherMembersText>{props.team.title}</OtherMembersText>
      <CollapsibleContent isOpen={isOpen} contentHeight={contentHeight}>
        <MembersContainer ref={contentRef}>
          {otherMembers.map((member, key) => (
            <TeamMemberContainer key={key} member={member} itemsPerRow={4} importPictures />
          ))}
        </MembersContainer>
      </CollapsibleContent>
      <ToggleButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <ExpandIcon />
      </ToggleButton>
    </TeamItemContainer>
  );
};

const MembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  margin-top: 40px;

  ${Device.tablet} {
    gap: 30px;
  }
`;

const OtherMembersText = styled.div`
  margin-top: 40px;
`;

const CollapsibleContent = styled.div<CollapsibleContentProps>`
  overflow: hidden;
  transition: height 0.5s ease;
  height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight + 50}px` : 0)};
`;

const ToggleButton = styled.button<ToggleButtonProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    width: 40px;
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;
