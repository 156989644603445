import { css } from 'styled-components';
import ArchiaThinTTF from '../assets/fonts/archia-thin-webfont.ttf';
import ArchiaLightTTF from '../assets/fonts/archia-light-webfont.ttf';
import ArchiaRegularTTF from '../assets/fonts/archia-regular-webfont.ttf';
import ArchiaMediumTTF from '../assets/fonts/archia-medium-webfont.ttf';
import ArchiaSemiBoldTTF from '../assets/fonts/archia-semibold-webfont.ttf';
import ArchiaBoldTTF from '../assets/fonts/archia-bold-webfont.ttf';

export const FontFace = css`
  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaThinTTF}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaRegularTTF}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archia';
    src: url(${ArchiaBoldTTF}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
`;
