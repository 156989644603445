import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import { Container } from '../Container';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { Device } from '../../constants/device';

export const About = (props: ProjectSectionProps) => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.about.length) return null;

  return (
    <Section id={'about'} inverse={props.inverse} hideHeading>
      <Container compact={true}>
        <AboutContainer>
          {project.about.map((d: string, index: number) => (
            <Content key={index} dangerouslySetInnerHTML={{ __html: d }} />
          ))}
        </AboutContainer>
      </Container>
    </Section>
  );
};

const Content = styled.p`
  a {
    color: ${Colors.primary};
    font-weight: 700;
  }
`;

const AboutContainer = styled.div`
  padding-top: 120px;

  ${Device.tablet} {
    padding-top: 0;
  }
`;
