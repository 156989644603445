export const Colors = {
  primary: process.env.REACT_APP_COLOR_PRIMARY ?? '#2F55B8',
  primaryDark: process.env.REACT_APP_COLOR_PRIMARY_DARK ?? '#162c66',
  primaryLight: process.env.REACT_APP_COLOR_PRIMARY_LIGHT ?? '#D2D8E5',
  background: process.env.REACT_APP_COLOR_BACKGROUND ?? '#F4FAFF',
  text: process.env.REACT_APP_COLOR_TEXT ?? '#11205E',

  white: '#ffffff',
  black: '#000000',
  grey: '#A1A4AF',
  lightGrey: '#B8BCCE',
  darkGrey: '#525664',
  darkerGrey: '#363636',
  red: '#b7244d',
};
