import { TeamMemberDTO } from '../model/TeamMemberDTO';
import { TeamMemberInterface } from '../model/ProjectInterface';

export const parseTeamMember = (data: TeamMemberDTO): TeamMemberInterface => ({
  photo: data.key + '.webp',
  name_cs: data.name,
  name_en: translateNameToEng(data.name),
  positions_cs: [data.title_cs],
  positions_en: [data.title_en],
});

const translateNameToEng = (value: string): string => {
  value = value.replace('Bc. ', '');
  value = value.replace('Ing. ', '');
  value = value.replace('Mgr. ', '');
  value = value.replace('BcA. ', '');
  value = value.replace(', Dis.', '');
  if (value.includes('MUDr.')) {
    value = value.replace('MUDr. ', '');
    value = `${value}, M.D.`;
  }
  return value;
};
