import axios from 'axios';
import { TeamMemberDTO } from '../model/TeamMemberDTO';
import { TeamMemberInterface } from '../model/ProjectInterface';
import { parseTeamMember } from '../parsers/parseTeamMember';

export const getTeamMember = async (name: string): Promise<TeamMemberInterface> => {
  const response = await axios.get(`https://raw.githubusercontent.com/mild-blue/about/master/people/${name}.json`);
  const memberData: TeamMemberDTO = response.data;
  return parseTeamMember(memberData);
};
