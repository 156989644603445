import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useAppContext } from './state/StateProvider';
import { getCompany } from './services/company.service';
import { getProject } from './services/project.service';
import { useIntl } from 'react-intl';
import { translateProject } from './i18n/translators/project.translator';
import { ProjectInterface } from './model/ProjectInterface';
import { CompanyInterface } from './model/CompanyInterface';
import { translateCompany } from './i18n/translators/company.translator';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Loading } from './components/Loading';
import { Timings } from './constants/timings';
import { ProjectSection } from './model/ProjectSection';
import { setGlobalCompany, setGlobalProject, setSections } from './state/Action';
import { AppLanguage } from './model/enums/AppLanguage';
import { useCookieConsent } from './helpers/useCookieConsent';
import { getOurTeam } from './services/getOurTeam';

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyInterface | undefined>(undefined);
  const [project, setProject] = useState<ProjectInterface | undefined>(undefined);
  const { state, dispatch } = useAppContext();
  const { locale } = useIntl();
  const cookieConsent = useCookieConsent();

  /** Get company on first load **/
  useEffect(() => {
    setLoading(true);

    const companyData = getCompany();
    // Set original company locally
    setCompany(companyData);
    // Set translated company globally
    dispatch(setGlobalCompany(translateCompany(companyData, locale)));
  }, []);

  /** Get project  **/
  useEffect(() => {
    const projectData = getProject();
    const alreadyInTeam: string[] = projectData.teams[0].members.map((obj) => obj.photo.split('.')[0]);
    const fetchTeamMembersAndUpdateProject = async () => {
      try {
        const ourTeamMembers = await getOurTeam(alreadyInTeam);
        const updatedProjectData = {
          ...projectData,
          teams: projectData.teams.map((team, index) => (index === 0 ? { ...team, members: [...team.members, ...ourTeamMembers] } : team)),
        };
        // Set original project locally
        setProject(updatedProjectData);
        // Set translated project globally
        dispatch(setGlobalProject(translateProject(updatedProjectData, locale)));
      } catch (error) {
        console.error('Failed to fetch team members:', error);
        // Set original project locally
        setProject(projectData);
        // Set translated project globally
        dispatch(setGlobalProject(translateProject(projectData, locale)));
      }
    };
    fetchTeamMembersAndUpdateProject();

    // Init Facebook domain verification
    if (projectData.facebook_domain_verification?.length) {
      const meta = document.createElement('meta');
      meta.name = 'facebook-domain-verification';
      meta.content = projectData.facebook_domain_verification;
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    // Init Meta Pixel
    if (projectData.pixel?.length) {
      ReactPixel.init(projectData.pixel);
      ReactPixel.pageView();
    }

    setTimeout(() => setLoading(false), Timings.LoadingTimeout);
  }, []);

  /** Set active sections on project change **/
  useEffect(() => {
    if (!state.project) {
      return;
    }

    const activeSections: ProjectSection[] = [];

    if (state.project.about.length) {
      activeSections.push('about');
    }

    if (state.project.points.length) {
      activeSections.push('features');
    }
    activeSections.push('references');
    activeSections.push('deployments');

    if (state.project.youtubeId) {
      activeSections.push('youtube');
    }

    if (!!state.project.appStore || !!state.project.googlePlay) {
      activeSections.push('download');
    }

    if (state.project.demo) {
      activeSections.push('demo');
    }

    if (state.project.faq?.questions) {
      activeSections.push('faq');
    }

    if (state.project.teams.length) {
      activeSections.push('teams');
    }

    if (!!state.project.email || !!state.company?.email) {
      activeSections.push('contact');
    }

    if (state.project.documents?.length) {
      activeSections.push('documents');
    }

    dispatch(setSections(activeSections));
  }, [state.project]);

  /** Localize company and project on locale change **/
  useEffect(() => {
    if (company) {
      // Set translated company globally
      dispatch(setGlobalCompany(translateCompany(company, locale)));
    }
    if (project) {
      // Set translated project globally
      const translatedProject = translateProject(project, locale);
      dispatch(setGlobalProject(translatedProject));

      // Change page title
      document.title = `${translatedProject.name}: ${translatedProject.subtitle} | Mild Blue`;
    }
  }, [locale, project]);

  /** Localize cookies consent on locale change **/
  useEffect(() => {
    cookieConsent.run(locale === AppLanguage.Czech ? AppLanguage.Czech : AppLanguage.English);
  }, [locale]);

  return (
    <>
      <Loading hide={!loading && state.project !== undefined} error={!loading && state.project === undefined} />
      {(() => {
        if (!loading && state.project !== undefined) {
          return (
            <>
              <Header />
              {children}
              <Footer />
            </>
          );
        }
      })()}
    </>
  );
};
